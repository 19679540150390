<template>
  <div>
    <HeaderNavHomepage />

    <div class="headings">
      <div class="headings-info">
        <h1 class="main-title">
          Join us in powering the subscription economy in the Philippines.
        </h1>
        <h2 class="sub-title">
          We're a motivated team of engineers and entrepreneurs building
          powerful tools for recurring revenue businesses.
        </h2>
        <a href="#view-openings" class="view-openings-btn">View Openings </a>
      </div>
    </div>

    <div class="company-bio">
      <div class="bio-info">
        <div class="bio-left-part">
          <h2 style="margin-top: 3rem" class="about-helixpay">
            About HelixPay:
          </h2>
          <p style="margin-top: 1rem; line-height: 2">
            HelixPay is the 1st ever subscription payments system in the
            Philippines. Modeled on multi-billion dollar subscription fintech
            companies in the US like Recurly and Recharge, HelixPay enables
            businesses in the Philippines to automatically charge customers on a
            recurring basis via credit/debit cards, bank transfer or wallet
            payments to maximize customer retention and revenue growth while
            offering a convenient user experience for customers.
          </p>
          <p style="margin-top: 1rem; line-height: 2">
            HelixPay targets businesses across the economy, from e-commerce DTC
            (direct-to-consumer) brands offering recurring monthly deliveries,
            to b2b software SaaS businesses, to “traditional” recurring revenue
            businesses like condo managers, media companies, content creators,
            and professional organizations that all need to collect recurring
            payments. While subscriptions are already a proven business model in
            the US, it’s a new segment in the Philippines and HelixPay offers a
            very exciting opportunity to enable and empower an entirely new part
            of the Philippines economy.
          </p>
          <p style="margin-top: 1rem; line-height: 2">
            We see a big opportunity in the Philippines and around the region:
            we plan to launch HelixPay operations around Southeast Asia.
          </p>

          <h2 class="we-are-hiring">
            <a href="#view-openings">We're hiring! </a>
          </h2>
        </div>

        <div class="bio-right-part">
          <div class="bio-right-part-info">
            <p style="font-weight: 700">PERKS & BENEFITS:</p>
            <p>• Competitive salary & stock options.</p>
            <p>• Comprehensive health insurance coverage.</p>
            <p>• Paid vacation and leaves.</p>
            <p>• Intense but flexible work schedule.</p>
            <p>• Hybrid between Makati office + remote work.</p>
            <p>• Work equipment and resources as needed.</p>
            <p>• Professional learning & development funds.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="line-break"></div>

    <h2 id="view-openings" class="current-openings">Current Openings</h2>

    <div class="business-info">
      <p style="margin-top: 4rem" class="sub-headings-current-openings">
        BUSINESS
      </p>

      <a
        v-for="(jobOpening, index) in businessJobOpenings"
        :key="`business_${index}`"
        :href="jobOpening.jobLink"
      >
        <div class="marketing-lead">
          <h4>{{ jobOpening.title }}</h4>
          <div class="right-info">
            <h6>{{ jobOpening.workType }}</h6>
            <p><i class="fa fa-arrow-right"></i></p>
          </div>
        </div>
      </a>
    </div>

    <div class="line-break-bot"></div>

    <div v-if="engineeringJobOpenings.length" class="engineering-info">
      <p class="sub-headings-current-openings">ENGINEERING / PRODUCT</p>

      <a
        v-for="(jobOpening, index) in engineeringJobOpenings"
        :key="`engineering_${index}`"
        :href="jobOpening.jobLink"
      >
        <div class="marketing-lead">
          <h4>{{ jobOpening.title }}</h4>
          <div class="right-info">
            <h6>{{ jobOpening.workType }}</h6>
            <p><i class="fa fa-arrow-right"></i></p>
          </div>
        </div>
      </a>
    </div>
    <MainFooter />
  </div>
</template>

<script>
import HeaderNavHomepage from '@/components/HeaderNavHomepage.vue';
import MainFooter from '@/components/MainFooter.vue';
import { buildPageMeta } from '@/utils/meta';
import { useHead } from '@vueuse/head';

export default {
  name: 'Careers',
  components: {
    MainFooter,
    HeaderNavHomepage,
  },

  setup() {
    useHead(
      buildPageMeta({
        title: 'Careers | View open roles at HelixPay',
        description:
          'Select option that is the best fit for your business needs. HelixPay Checkout is custom built for subscriptions and recurring payments.',
        url: 'https://www.helixpay.ph/careers',
        image: 'https://www.helixpay.ph/img/career-meta-img.png',
      })
    );
  },

  data: () => ({
    engineeringJobOpenings: [
      // {
      //   jobLink:
      //     'https://www.kalibrr.com/c/goodwork/jobs/196571/fullstack-developer?ref=recruiter_job_card_clipboard_384660',
      //   title: 'Full Stack Developer',
      //   workType: 'Remote Work',
      // },
    ],
    businessJobOpenings: [
      // {
      //   jobLink:
      //     'https://www.kalibrr.com/c/goodwork/jobs/208557/account-manager-2?ref=recruiter_job_card_clipboard_384660 ',
      //   title: 'Key Account Manager',
      //   workType: 'Hybrid',
      // },
      // {
      //   jobLink:
      //     'https://www.kalibrr.com/c/goodwork/jobs/202360/business-development-associate-3?ref=recruiter_job_card_clipboard_384660',
      //   title: 'Business Development Associate',
      //   workType: 'Hybrid',
      // },
      // {
      //   jobLink:
      //     'https://ph.indeed.com/job/business-development-intern-a4cd7e2a7eafcc26',
      //   title: 'Business Development Intern',
      //   workType: 'Remote Work',
      // },
      // {
      //   jobLink:
      //     'https://www.kalibrr.com/c/goodwork/jobs/192672/compliance-officer?ref=recruiter_job_card_clipboard_384660',
      //   title: 'Compliance Officer',
      //   workType: 'Hybrid',
      // },
      {
        jobLink:
          'https://www.kalibrr.com/c/helixpay/jobs/244596/marketing-associate-3?dashboard_view=published&source=Jobs%2BPage&user_ref=www.kalibrr.com_organic_login',
        title: 'Marketing Associate',
        workType: 'Hybrid',
      },
      {
        jobLink:
          'https://www.kalibrr.com/c/helixpay/jobs/244595/performance-marketing-analyst-3?dashboard_view=published&source=Jobs%2BPage&user_ref=www.kalibrr.com_organic_login',
        title: 'Performance Marketing Analyst',
        workType: 'Hybrid',
      },
      {
        jobLink:
          'https://www.kalibrr.com/c/helixpay/jobs/239739/legal-officer?dashboard_view=published&source=Jobs%2BPage&user_ref=www.kalibrr.com_organic_login',
        title: 'Legal Officer',
        workType: 'Remote Work',
      },
    ],
  }),
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.headings {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
}

.headings-info {
  display: grid;
  grid-template-columns: 1fr;
  width: 60rem;
  margin: auto;
}

.main-title {
  width: 70%;
  line-height: 1.5;
  font-size: 2rem;
  font-weight: 700;
  margin: auto;
  text-align: center;
  margin-top: 8rem;
}

.sub-title {
  line-height: 2;
  width: 50%;
  font-size: 1rem;
  font-weight: 700;
  margin: auto;
  margin-top: 1rem;
  color: rgb(116, 116, 116);
  text-align: center;
}

.view-openings-btn {
  width: 20%;
  margin: auto;
  display: block;
  background-color: #25a4e1;
  font-size: 1rem;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  color: white;
  padding: 20px 20px;
  border-radius: 5px;
  margin-top: 2rem;
  font-weight: 700;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease-in-out;
}

.view-openings-btn:hover {
  color: white;
  background-color: #2094ca;
}

.company-bio {
  margin-top: 5rem;
  width: 100%;
}

.bio-info {
  width: 71rem;
  margin: auto;
  align-items: center;
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 2rem;
}

.about-helixpay {
  font-size: 2rem;
  font-weight: 500;
  width: 100%;
  background: linear-gradient(to right, #25a4e1, #25b474);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bio-left-part {
  width: 100%;
}

.bio-left-part p {
  font-size: 1rem;
  margin-right: 2rem;
  line-height: 1.5;
}

.bio-right-part {
  width: 94%;
  line-height: 2.5;
  text-align: left;
  padding: 0.5rem;
  border-top: 6px solid #25a4e1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.bio-right-part-info {
  padding: 2rem;
  font-size: 1rem;
}

.we-are-hiring {
  font-size: 1.5rem;
  font-weight: 600;
  width: 100%;
  margin: auto;
  margin-bottom: 4rem;
  margin-top: 1rem;
  background: linear-gradient(to right, #25a4e1, #25b474, #25a4e1, #25b474);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.line-break {
  margin-top: 3rem;
  height: 3px;
  width: 71rem;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(to right, #25a4e1, #25b474);
}

.line-break-bot {
  margin-top: 3rem;
  height: 3px;
  width: 71rem;
  margin-left: auto;
  margin-right: auto;
  background: white;
}

.current-openings {
  font-size: 2rem;
  width: 71rem;
  margin: auto;
  margin-top: 4rem;
  background: linear-gradient(to right, #25a4e1, #25b474);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.business-info {
  width: 71rem;
  margin: auto;
}

.business-info p,
.engineering-info p {
  color: #25a4e1;
  font-weight: 700;
}

.marketing-lead {
  align-items: center;
  margin-top: 1rem;
  padding: 0.7rem;
  display: grid;
  grid-template-columns: 70% 30%;
  transition: 0.35s ease-in-out;
  border-radius: 5px;
}

h4 {
  font-size: 1rem;
  font-weight: 500;
}

.marketing-lead:hover {
  background-color: #83d8ff;
}

.right-info {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.right-info h6 {
  font-size: 1rem;
  font-weight: 500;
}

.right-info p {
  font-size: 1rem;
  color: black;
}

a {
  text-decoration: none;
  color: black;
}

.engineering-info {
  padding-top: 4rem;
  padding-bottom: 5rem;
  width: 71rem;
  margin: auto;
}

p {
  opacity: 0.6;
  font-weight: 500;
}

@media only screen and (max-width: 1300px) {
  .bio-info {
    width: 45rem;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr;
  }

  .bio-left-part {
    width: inherit;
  }

  .bio-right-part {
    width: 35rem;
    margin: auto;
  }

  .line-break,
  .line-break-bot {
    width: 45rem;
  }

  #view-openings {
    width: 45rem;
    margin: auto;
    margin-top: 4rem;
  }

  .business-info,
  .engineering-info {
    width: 45rem;
    margin: auto;
  }
}

@media only screen and (max-width: 1000px) {
  .headings-info {
    width: 40rem;
    margin: auto;
  }

  .main-title,
  .sub-title {
    width: 100%;
  }

  .view-openings-btn {
    width: 100%;
  }

  .bio-info {
    width: 40rem;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr;
  }

  .line-break,
  .line-break-bot {
    width: 40rem;
  }

  .business-info,
  .engineering-info {
    width: 40rem;
    margin: auto;
  }

  .right-info {
    width: 11rem;
  }
}

@media only screen and (max-width: 800px) {
  .headings-info {
    width: 30rem;
    margin: auto;
  }

  .bio-info {
    width: 30rem;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr;
  }

  .bio-right-part {
    width: 30rem;
    margin: auto;
  }

  .line-break,
  .line-break-bot {
    width: 30rem;
  }

  .right-info {
    width: 11rem;
  }

  .business-info,
  .engineering-info {
    width: 30rem;
    margin: auto;
  }

  .marketing-lead {
    display: grid;
    grid-template-columns: 1fr;
    margin: auto;
    align-items: center;
    text-align: center;
  }

  .business-info .right-info {
    width: 20%;
  }

  .business-info .right-info h6 {
    width: 70%;
  }

  .business-info .right-info p {
    width: 30%;
  }

  .right-info {
    width: 40%;
    margin: auto;
  }

  .right-info h6 {
    width: 80%;
  }

  .right-info p {
    width: 20%;
  }
}

@media only screen and (max-width: 650px) {
  .headings-info {
    width: 90%;
    margin: auto;
  }

  .headings-info h1 {
    font-size: 1.5rem;
  }

  .headings-info h2 {
    font-size: 1rem;
    font-weight: 400;
  }

  .bio-info {
    width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr;
    gap: 0;
  }

  .bio-left-part,
  .bio-right-part {
    width: 100%;
    margin: auto;
  }

  .bio-left-part p {
    margin-right: 0;
  }

  .about-helixpay {
    font-size: 1.5rem;
    width: 100%;
    margin: auto;
    text-align: left;
    background: linear-gradient(to right, #25a4e1, #25b474);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .bio-right-part-info p {
    padding: 0;
    font-size: 1rem;
  }

  .we-are-hiring {
    width: 100%;
  }

  .sub-headings-current-openings {
    width: 85%;
    margin: auto;
  }

  .line-break,
  .line-break-bot {
    width: 90%;
  }

  .main-title {
    width: 100%;
  }

  #view-openings {
    width: 90%;
  }

  .business-info p,
  .engineering-info p {
    width: 100%;
  }

  .business-info,
  .engineering-info {
    width: 90%;
    margin: auto;
  }

  .business-info .right-info {
    width: 30%;
  }

  .business-info .right-info h6 {
    width: 70%;
  }

  .business-info .right-info p {
    width: 30%;
  }

  .right-info {
    width: 60%;
    margin: auto;
  }

  .right-info h6 {
    width: 80%;
  }

  .right-info p {
    width: 20%;
  }
}
</style>
